import { BaseRoutes } from './baseRoutes';
import { parseJwt } from './microfrontends/shared/utilities/parseToken';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(prefix => location.href.indexOf(`${location.origin}${prefix}`) !== -1);

const isValidToken = (location: Location): void => {
  const token = window.localStorage.getItem('currentUser');
  if (location.href !== `${location.origin}/login`) {
    if (token) {
      const expTS = parseJwt(token).exp;
      const date = new Date(expTS * 1000);
      if (date < new Date()) {
        location.assign('/login');
      }
    } else {
      location.assign('/login');
    }
  }
};

const routefunction = (route: string) => (location: Location): boolean => {
  isValidToken(location);
  return prefix(location, route);
};
const mfApplications = routefunction(`${BaseRoutes.INITIAL}/applications`);
const mfLogin = routefunction(`${BaseRoutes.INITIAL}/login`);
const app = (): boolean => true;

export default {
  app,
  mfLogin,
  mfApplications,
};
