import { registerApplication, start } from 'single-spa';
import * as serviceWorker from './serviceWorker';

import Activity from './activityFns';
import { BaseRoutes } from './baseRoutes';

const registerApps = (): void => {
  registerApplication('app', () => import('./microfrontends/app.lifecycle'), Activity.app);
  registerApplication('mfLogin', () => import('./microfrontends/mfLogin/mfLogin.lifecycle'), Activity.mfLogin, {
    baseRoute: BaseRoutes.INITIAL,
  });
  registerApplication(
    'mfApplications',
    () => import('./microfrontends/mfApplications/mfApplications.lifecycle'),
    Activity.mfApplications,
    {
      baseRoute: BaseRoutes.INITIAL,
    },
  );
};

registerApps();
start();

serviceWorker.unregister();
